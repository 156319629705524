.search-button-container {
  text-align: right;
  margin-top: auto;
}

.date-range-group {
  display: flex;
}

.date-range-group-spacer {
  width: 0.5em;
}
