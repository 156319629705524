@import '~@amzn/awsui-design-tokens/polaris.scss';

.json-indent {
  padding-left: 3em;
}
.json-expand-button {
  user-select: none;
  width: 1.75em;
  margin-left: -2em;
  margin-right: 0.25em;
  border: 1px solid $color-text-link-default;
  color: $color-text-link-default;
  border-radius: 3px;
  padding: 0;
  cursor: pointer;
}
.json-expand-button:hover {
  background-color: $color-text-link-default;
  color: white;
}
