.tabs-container {
  flex: 0 0 auto;

  .tabs-tab {
    flex: 0 0 auto;
    font-weight: 800;
    font-size: 1em;
    text-transform: upper;
    margin-left: 7px;
    margin-right: 7px;
    display: inline-flex;
    padding: 10px;

    color: #FFF;
  }
}