@import '~@amzn/awsui-design-tokens/polaris.scss';

.process-log {
  display: flex;
  flex-direction: column;
}
.process-log-row {
  display: flex;
  padding: 0.25em;
  align-items: center;
}
.process-log-row:nth-child(even) {
  background-color: $color-border-container-top;
}
.process-log-timestamp {
  color: $color-text-body-secondary;
  white-space: nowrap;
  width: 12.5em;
  min-width: 12.5em;
}
.process-log-description {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.process-log-description:hover {
  white-space: normal;
}
.process-log-self-service-button {
  white-space: nowrap;
  margin-left: auto;
}
