.awsui awsui-badge.fixed-badge .awsui-badge-content {
  width: 6em;
  text-align: center;
}
.awsui .popover-invalid-message .awsui-popover__trigger-text {
  border-bottom: none;
}
.awsui .popover-invalid-message .awsui-badge-content {
  text-decoration: underline dashed;
  cursor: pointer;
}
