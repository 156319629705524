@import '~@amzn/awsui-design-tokens/polaris.scss';

.dropzone {
  height: 15em;
  border-width: 5px;
  border-style: dashed;
  border-color: $color-border-control-default;

  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
}
.dropzone.dragging {
  border-color: $color-border-item-focused;
}
