.logo-wikilinks, .logo-feedback, .logo-is-it-down {
  flex: 0 0 auto;
  font-weight: 800;
  font-size: 1em;
  text-transform: upper;
  margin-left: 7px;
  margin-right: 7px;
  display: inline-flex;
  a:link,
  a:visited {
    text-decoration: none;
    color: white;
  }
  a:hover,
  a:active {
    text-decoration: underline;
    color: white;
  }
  img {
    margin-left: 5px;
  }
}